<template>
	<div class="m-grid__item m-grid__item--fluid m-wrapper">
		<div class="container-fluid pt-4">
			<div class="m-grid__item m-grid__item--fluid m-wrapper">
				<div class="m-content mt-3">
					<div class="row">
						<div class="col-xl-12 col-lg-12">
							<div class="text-center mb-5">
									<img alt src="@/assets/img/logo/claro-logo.png" height="50px">
							</div>
							<div class="m-portlet">
								<div class="m-portlet__head">
									<div class="m-portlet__head-caption">
										<div class="m-portlet__head-title">
											<h3 class="m-portlet__head-text">Complete seu cadastro</h3>
										</div>
									</div>
								</div>
								<div>
									<div class="m-portlet__body">
										<div class="row">
											<div class="col-6">
												<div class="form-group m-form__group">
													<label for="example_input_full_name">Empresa</label>
													<input
														type="text"
														disabled="disabled"
														v-model="invite.company.name"
														name="first_name"
														class="form-control m-input"
													>
												</div>
											</div>
											<div class="col-6">
												<div class="form-group m-form__group">
													<label for="example_input_full_name">Email</label>
													<input
														type="email"
														disabled="disabled"
														v-model="invite.email"
														class="form-control m-input"
													>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12"></div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="form-group m-form__group">
													<label for="example_input_full_name">Nome</label>
													<input
														type="text"
														v-model="invite.first_name"
														name="first_name"
														class="form-control m-input"
													>
												</div>
											</div>
											<div class="col-6">
												<div class="form-group m-form__group">
													<label for="example_input_full_name">Sobrenome</label>
													<input
														type="text"
														v-model="invite.last_name"
														name="last_name"
														class="form-control m-input"
													>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="form-group m-form__group">
													<label for="example_input_full_name">Código do Convite</label>
													<input type="text" v-model="invite.hash" name="invite" class="form-control m-input">
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="form-group m-form__group">
													<label for="example_input_full_name">Senha</label>
													<input
														type="password"
														v-model="invite.password"
														name="password"
														class="form-control m-input"
													>
												</div>
											</div>
											<div class="col-6">
												<div class="form-group m-form__group">
													<label for="example_input_full_name">Confirmar Senha</label>
													<input
														type="password"
														v-model="invite.password_confirmation"
														name="password_confirmation"
														class="form-control m-input"
													>
												</div>
											</div>
										</div>
									</div>
									<div class="m-portlet__foot">
										<div class="row align-items-right text-right">
											<div class="col-lg-12">
												<button type="button" class="btn btn-brand" @click="save">Salvar</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "register",
	data() {
		return {
			invite: {
				first_name: "",
				last_name: "",
				hash: "",
				password: "",
				password_confirmation: "",
				company: {
					name: ""
				}
			}
		};
	},
	created() {
		this.clearLocalStorage();
		this.getInvite();
	},
	methods: {
		clearLocalStorage() {
			localStorage.removeItem('user');
			localStorage.removeItem('token');
			localStorage.removeItem('scopes');
			localStorage.removeItem('serviceFilter');
		},
		save() {
			axios
				.post("/user/store", this.invite)
				.then(res => {
					swal("sucesso", "Cadastro criado com Sucesso!", "success").then(res =>
						this.$router.push({ name: "login" })
					);
				})
				.catch(error => {
					if (error.response && error.response.data && error.response.status === 422) {
						let errorKeys = Object.keys(error.response.data.errors);
						let errorMessages = "<h5>Verifique os erros!</h5>";
						errorKeys.forEach(key => {
							errorMessages += `<li class="text-left">${error.response.data.errors[key][0]}</li>`;
						});
						swal("Ops!", errorMessages, "error");
					} else {
						swal("Ops!", "Usuário já cadastrado", "error").then(() => {
							this.$router.push({name: 'login'});
						});
					}
				});
		},
		getInvite() {
			axios
				.get(`/get-invite/${this.$route.params.email}`)
				.then(response => {
					console.log(response);
					this.invite = {
						company: response.data.company,
						email: response.data.invite.email,
						company_id: response.data.invite.company_id,
						profile_id: response.data.invite.profile_id,
						id: response.data.invite.id,
						role_id: response.data.invite.role_id
					};
				})
				.catch(error =>
					swal("Ops!", "Convite inválido ou expirado!", "error").then(res =>
						this.$router.push({ name: "login" })
					)
				);
		}
	},
};
</script>

<style>
</style>
